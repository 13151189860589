<template>
    <div class="vx-card p-6">

        <div class="vx-row">
            <div class="vx-col md:w-3/4 w-full">

                <!-- Col Header -->
                <div class="flex items-end mb-3">
                    <feather-icon icon="SidebarIcon" class="mr-2" svgClasses="w-6 h-6" />
                    <span class="leading-none font-medium fsz font-bold">{{ $t('menuCustomer.contactInfo') }}</span>
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-4">
                        <label class="vs-input--label">{{ $t('menuOrganization.branch') }}</label>
                        <span class="text-danger"> *</span>
                        <v-select name="organizationBranchName" v-validate="'required'" class="" v-model="selectedOrganizationBranch" :clearable="false"
                                :options="organizationBranchOptions">
                            <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                        </v-select>
                        <span class="text-danger text-sm">{{ errors.first('organizationBranchName') }}</span>
                    </div>
                    <!-- <div class="vx-col md:w-1/3 w-full mt-4" v-if="isUpdate">
                        <label class="vs-input--label required">{{$t('menuCustomer.customerCode')}}</label>
                        <vs-input class="w-full" :disabled="isUpdate" v-model="customerInfo.customerCode"
                            v-validate="'required'" name="customerCode" />
                        <span class="text-danger text-sm">{{ errors.first('customerCode') }}</span>
                    </div> -->

                    <div class="vx-col md:w-1/3 w-full mt-4">
                        <label class="vs-input--label required">{{$t('menuCustomer.customerName')}}</label>
                        <vs-input class="w-full" v-model="customerInfo.fullName" v-validate="'required'"
                            name="lastName" />
                        <span class="text-danger text-sm">{{ errors.first('lastName') }}</span>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mt-4">
                        <label class="vs-input--label required">{{$t('menuCustomer.identityCard')}}</label>
                        <vs-input class="w-full" v-model="customerInfo.identityCard" v-validate="'required|numeric|min:9|max:12'"
                            name="identityCard" />
                        <span class="text-danger text-sm">{{ errors.first('identityCard') }}</span>
                    </div>
                </div>

                <div class="vx-row mt-2">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label required">{{$t('menuCustomer.phoneNumber')}}</label>
                        <vs-input class="w-full" v-model="customerInfo.phone"
                            v-validate="'required|numeric|min:10|max:11'" name="phoneNumber" />
                        <span class="text-danger text-sm">{{ errors.first('phoneNumber') }}</span>
                    </div>

                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label required">{{ $t('menuCustomer.customerType') }}</label>
                        <v-select class="w-full" v-model="customerType" :options="customerTypeOptions" name="customerType" v-validate="'required'">
                            <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                        </v-select>
                        <span class="text-danger text-sm">{{ errors.first('customerType') }}</span>
                    </div>

                    <!-- <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label required">{{$t('menuCustomer.dateCreated')}}</label>
                        <datepicker :highlighted="highlighted" :disabledDates="disabledFn" :language="vi"
                            :format="'dd/MM/yyyy'" v-model="customerInfo.createdDate" data-vv-validate-on="input"
                            v-validate="'required'" placeholder="Chọn ngày đăng ký ..." name="createdDate"></datepicker>

                        <span class="text-danger text-sm">{{ errors.first('createdDate') }}</span>
                    </div> -->
                </div>
            </div>
            <div class="vx-col md:w-1/4 w-full" style="border-left: 1px solid #DCDCDC;">
                <!-- Col Header -->
                <div class="flex mb-3 items-end ml-12">
                    <feather-icon icon="MaximizeIcon" class="mr-2" svgClasses="w-6 h-6" />
                    <span class="leading-none font-medium fsz font-bold">{{ $t('menuCustomer.faceData') }}</span>
                </div>
                <div class="vx-row">
                    <vue-upload-multiple-image class="m-auto" @upload-success="uploadImageSuccess"
                        @before-remove="beforeRemove" @edit-image="editImage" @data-change="dataChange"
                        :data-images="images">
                    </vue-upload-multiple-image>
                </div>
            </div>
        </div>

        <div class="vx-row">
            <div class="vx-col md:w-3/4 w-full">
                <!-- Col Header -->
                <div class="flex items-end mt-8 mb-3">
                    <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-6 h-6" />
                    <span class="leading-none font-medium fsz font-bold">{{ $t('menuCustomer.address') }}</span>
                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">{{$t('menuCustomer.organization')}}</label>
                        <vs-input class="w-full" v-model="customerInfo.description" name="organization" />
                        <!-- <span class="text-danger text-sm">{{ errors.first('organization') }}</span> -->
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">{{ $t('menuCustomer.city') }}</label>
                        <v-select class="" v-model="province" :clearable="false" :options="cityOptions" name="city">
                            <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                        </v-select>
                        <!-- <span class="text-danger text-sm">{{ errors.first('city') }}</span> -->
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">{{ $t('menuCustomer.district') }}</label>
                        <v-select class="" v-model="district" :clearable="false" :options="districtOptions"
                            name="district">
                            <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                        </v-select>
                        <!-- <span class="text-danger text-sm">{{ errors.first('district') }}</span> -->
                    </div>

                </div>
                <div class="vx-row">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">{{ $t('menuCustomer.commune') }}</label>
                        <v-select class="" v-model="subDistrict" :clearable="false" :options="subDistrictOptions"
                            name="subDistrict">
                            <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                        </v-select>
                        <!-- <span class="text-danger text-sm">{{ errors.first('subDistrict') }}</span> -->
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">{{ $t('menuCustomer.houseNumber') }}</label>
                        <vs-input class="w-full" v-model="customerInfo.houseNumber" name="houseNumber" />
                        <!-- <span class="text-danger text-sm">{{ errors.first('houseNumber') }}</span> -->
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label">{{ $t('menuCustomer.address') }}</label>
                        <vs-input class="w-full" v-model="fullAddressModel" name="full_address"
                            readonly="true" />
                    </div>
                </div>
            </div>
            <div class="vx-col md:w-1/4 w-full" style="border-left: 1px solid #DCDCDC;">
                <!-- Col Header -->
                <div class="flex items-end mt-8 mb-3 ml-12">
                    <feather-icon icon="RadioIcon" class="mr-2" svgClasses="w-6 h-6" />
                    <span class="leading-none font-medium fsz font-bold">{{ $t('navMenu.externalDevice') }}</span>
                </div>

                <div class="vx-row mt-4">
                    <vs-button class="mt-4 m-auto" icon-pack="feather" icon="icon-plus" @click="openPopupCard"
                        v-if="!customerInfo.cardId">
                        {{ $t('button.addCard') }}
                    </vs-button>
                </div>

                <div class="vx-row" v-show="customerInfo.cardId">
                    <div class="vx-col md:w-3/4 w-full m-auto" >
                        <card-customer style="min-height:280px" class="mt-4" :data="customerInfo" :isEdit="isUpdate && customerInfo.cardId && customerInfo.cardStaff" :disableCard="true" />
                    </div>
                </div>
            </div>
        </div>

        <!-- Save & Reset Button -->
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="mt-8 flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{
                    `${isUpdate ?
                    $t('button.update') : $t('button.create')}`
                    }}
                    </vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="warning" @click="$router.back()">
                        {{$t('button.back')}}
                    </vs-button>
                </div>
            </div>
        </div>

        <vs-popup title="Chọn thẻ" :active.sync="activePopup">
            <div class="vx-row">
                <div class="vx-col w-full text-center" v-if="listCards.length === 0">
                    <span>Không có dữ liệu</span>
                </div>
                <div class="vx-col md:w-1/3 w-full" v-for="(card, indexCard) in listCards" :key="indexCard" v-else>
                    <div class="w-full" @click="chooseCard(card)">
                        <card-customer class="mt-4" :data="card" :isEdit="false" />
                    </div>
                </div>
            </div>
        </vs-popup>
    </div>
</template>

<script>

import VueUploadMultipleImage from '../../../components/VueUploadMultipleImage'
import province from '@/assets/utils/province.js';
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
import { en, vi } from "vuejs-datepicker/dist/locale";
import moment from "moment";
import CardCustomer from '../card-customer/card/CardCustomer.vue'
import authConfig from '@/../auth_config.json';

export default {
    components: {
        VueUploadMultipleImage,
        vSelect,
        Datepicker,
        CardCustomer
    },
    data() {
        return {
            selectedOrganizationBranchValue: null,
            organizationBranchOptions: [],
            cityOptions: province.getProvince(),
            districtOptions: [],
            subDistrictOptions: [],
            isUpdate: false,
            customerInfo: {
                id: null,
                customerCode: null,
                fullName: null,
                phone: null,
                organizationId: null,
                city: null,
                district: null,
                fullAddress: null,
                houseNumber: null,
                cardId: null,
                description: null,
                accessInOut: '0',
                organizationBrandId: null,
                type: null,
                urlImage: ''
            },
            images: [],
            user_data: null,
            imagesId: [],
            highlighted: {
                dates: [
                    new Date()
                ]
            },
            disabledFn: {
                customPredictor(date) {
                    if (moment(date).isBefore(moment(), 'days')) {
                        return true;
                    }
                }
            },
            en: en,
            vi: vi,
            activePopup: false,
            cardCustomer: {
                cardId: null
            },
            listCards: [],
            customerTypeOptions: [
                {value: 0, label: this.$t('menuCustomer.customer')},
                {value: 1, label: this.$t('menuCustomer.passers')}
            ]
        }
    },
    async created() {
        let branchList = JSON.parse(localStorage.getItem('branchInfoList') || '[]');
        if (branchList.length === 0) {
            return this.$vs.notify({
                text: this.$t('menuOrganization.noBranch'),
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            });
        }
        this.organizationBranchOptions = branchList.map(item => {
            return {value: item.id, label: item.organizationBranchName}
        });
        await this.getDataCard();
        let userInfo = localStorage.getItem("userInfo");
        this.customerInfo.organizationId = JSON.parse(userInfo).organization.id;
        this.customerInfo.status = true

        this.customerInfo.id = this.$route.query.id;
        if (this.customerInfo.id) {
            this.isUpdate = true;
            await this.findById();
            for (const x of this.customerInfo.imageStore) {
                let data = await this.toDataURL(x.url);
                let dataImage = data.xhr.response;
                let metadata = {
                    type: 'image/jpeg'
                };
                let file = new File([dataImage], x.fileName, metadata);
                this.images.push({
                    name: x.fileName,
                    path: data.imgBase64,
                    highlight: 1,
                    default: 1,
                    file: file
                });
            }
        }

        let imageUrl = this.$route.query.imageUrl
        if (imageUrl) {
            this.customerInfo.imageUrl = authConfig.processorDomain + "storage/" + imageUrl
            let data = await this.toDataURL(this.customerInfo.imageUrl);
            let dataImage = data.xhr.response;
            let metadata = {
                type: 'image/jpeg'
            };
            let file = new File([dataImage], "avatar", metadata);
            this.images.push({
                name: "avatar",
                path: data.imgBase64,
                highlight: 1,
                default: 1,
                file: file
            });
        }
    },
    computed: {
        selectedOrganizationBranch: {
            get() {
                let object = this.organizationBranchOptions.find(el => el.value == this.customerInfo.organizationBranchId);
                if (!object) return;
                return {
                    label: object.label,
                    value: object.value
                }
            },
            set(obj) {
                this.customerInfo.organizationBranchId = obj ? obj.value : null;
            }
        },
        validateForm() {
            return !this.errors.any()
        },
        province: {
            get() {
                return {
                    label: this.getLabelString(this.cityOptions.find(x => x.value === this.customerInfo.city)),
                    value: this.customerInfo.city
                }
            },
            set(obj) {
                if (obj.value) {
                    this.customerInfo.city = obj.value;
                    this.districtOptions = province.getDistrict(obj.value);
                    this.customerInfo.district = this.districtOptions[0].value;
                    this.subDistrictOptions = province.getSubDistrict(this.customerInfo.city, this.customerInfo.district);
                    this.customerInfo.street = this.subDistrictOptions[0].value;
                } else {
                    this.customerInfo.city = null;
                    this.districtOptions = [{ value: null, label: this.$t('menuCustomer.all') }];
                    this.customerInfo.district = null;
                    this.subDistrictOptions = [{ value: null, label: this.$t('menuCustomer.all') }];
                    this.customerInfo.street = null;
                }
            }
        },
        district: {
            get() {
                return {
                    label: this.getLabelString(this.districtOptions.find(x => x.value === this.customerInfo.district)),
                    value: this.customerInfo.district
                }
            },
            set(obj) {
                this.customerInfo.district = obj.value;
                this.subDistrictOptions = province.getSubDistrict(this.customerInfo.city, obj.value);
                this.customerInfo.street = this.subDistrictOptions[0].value;
            }
        },
        subDistrict: {
            get() {
                return {
                    label: this.getLabelString(this.subDistrictOptions.find(x => x.value === this.customerInfo.street)),
                    value: this.customerInfo.street
                }
            },
            set(obj) {
                this.customerInfo.street = obj.value
            }
        },
        fullAddressModel: {
            get() {
                if (this.province.value) {
                    let fullAddress = `${this.customerInfo.houseNumber ? this.customerInfo.houseNumber + ', ' : ''}${this.subDistrict.label ? this.subDistrict.label + ', ' : ''}${this.district.label}, ${this.province.label}`;
                    this.customerInfo.fullAddress = fullAddress;
                    return fullAddress;
                } else {
                    return null;
                }
            },
        },
        cardCustomerModel: {
            get() {
                let object = this.listCards.find(el => el.value == this.cardCustomer.cardId);
                if (!object) return;
                return {
                    label: object.label,
                    value: object.value
                }
            },
            set(object) {
                this.cardCustomer.cardId = object ? object.value : null;
            }
        },
        customerType: {
            get() {
                let object = this.customerTypeOptions.find(el => el.value === this.customerInfo.type);
                if (!object) return;
                return {
                    label: object.label,
                    value: object.value
                }
            },
            set(obj) {
                this.customerInfo.type = obj.value
            }
        }
    },

    watch: {
        '$store.state.isDeleteCardCustomer'(val) {
            if (val) {
                this.customerInfo.cardId = null;
                this.$store.commit('DELETE_CARD', false);
                this.getDataCard();
            }
        }
    },

    methods: {
        organizationBranchString(id) {
            let organizationBranch = this.organizationBranchOptions.find(x => x.value === id);
            return (organizationBranch && organizationBranch.label) || this.$t('menuOrganization.null');
        },
        toDataURL(url) {
            return new Promise(resolve => {
                let xhr = new XMLHttpRequest();
                xhr.onload = function () {
                    let reader = new FileReader();
                    reader.onloadend = function () {
                        resolve({ imgBase64: reader.result, xhr: xhr });
                    }
                    reader.readAsDataURL(xhr.response);
                };
                xhr.open('GET', url);
                xhr.responseType = 'blob';
                xhr.send();
            })
        },
        findById() {
            return new Promise((resolve, reject) => {
                this.$crm.get('customer/find-by-id/' + this.customerInfo.id).then((response) => {
                    this.customerInfo = response.data;
                    this.customerInfo.cardId = response.data.cardId;
                    this.districtOptions = province.getDistrict(this.customerInfo.city);
                    this.subDistrictOptions = province.getSubDistrict(this.customerInfo.city, this.customerInfo.district);
                    this.customerInfo.urlImage = response.data.imageStore.length > 0 ?  response.data.imageStore[0].url : null;
                    this.isUpdate = true;
                    if (this.customerInfo.cardId || this.customerInfo.cardNumber) {
                        this.customerInfo.cardStatus = 1;
                        this.customerInfo.cardStaffType = 0;
                        this.customerInfo.customerType = this.$t('menuCustomer.customer');
                        this.customerInfo.cardNumber = response.data.cardStaff.cardNumber;
                    }
                    resolve(true);
                }).catch((err) => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: 'Error',
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                    reject(false);
                });
            })
        },
        uploadImageSuccess(formData, index, fileList, file) {
            this.images = fileList;
            this.customerInfo.urlImage = fileList.length > 0 ? fileList[0].path : null;
            this.customerInfo.accessInOut = '0'
            this.setIsUpdateImages();
        },
        beforeRemove(index, done, fileList) {
            this.images = fileList
            this.setIsUpdateImages();
            done();
        },
        editImage(formData, index, fileList) {
            this.images = fileList;
            this.setIsUpdateImages();
        },
        dataChange(data) {
            this.images = fileList;
            this.setIsUpdateImages();
        },
        setIsUpdateImages() {
            if (this.isUpdate) {
                this.isUpdateImages = true;
            }
        },
        getLabelString(object) {
            return (object && object.label) || this.$t('menuCustomer.undefined');
        },

        manufactureString(type) {
            let typeString = this.manufactureOptions.find(x => x.value === type);
            return (typeString && typeString.label) || this.$t('menuCustomer.undefined');
        },

        async saveChanges(evt) {
            evt.preventDefault();
            if (!this.validateForm) return;
            const result = await this.$validator.validateAll();
            if (!result) return;

            if (this.images.length != 0 && !this.isUpdate || this.isUpdate && this.isUpdateImages) {
                if (this.images.length != 0) {
                    await this.handleClickUploadFile();
                } else {
                    this.imagesId = [];
                }

                this.customerInfo.images = this.imagesId;
            }

            if (!this.customerInfo.cardId && this.images.length == 0) {
                return this.$vs.notify({
                    color: 'danger',
                    text: 'Hãy thêm dữ liệu khuôn mặt hoặc thiết bị ngoại vi!',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle'
                })
            }

            delete this.customerInfo.imageStore;

            let url = this.isUpdate ? `/customer/update` : `/customer/create`;
            this.$vs.loading();

            this.$crm.post(url, this.customerInfo).then((res) => {
                this.$vs.notify({
                    color: 'success',
                    text: `${this.isUpdate ? this.$t('menuCustomer.updateCustomerSS') : this.$t('menuCustomer.addCustomerSS')}`,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
                this.$router.back();
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            })
        },

        handleClickUploadFile() {
            return new Promise((resolve, reject) => {
                let formData = new FormData(), config = {};
                this.images.forEach((f, i) => {
                    formData.append('files', f.file);
                });
                config = {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                };
                this.$vs.loading();
                this.$crm.post('/employee/image/upload', formData, config).then((res) => {
                    this.imagesId = [];
                    this.imagesId = res.data;
                    this.$vs.loading.close();
                    resolve(true);
                }).catch((err) => {
                    reject(true);
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            })
        },

        chooseCard: function (card) {
            this.customerInfo.cardNumber = card.cardNumber;
            this.customerInfo.cardId = card.value;
            this.customerInfo.cardStaffType = card.cardStaffType;
            this.customerInfo.customerType = this.$t('menuCustomer.customer');
            this.customerInfo.cardId = card.value;
            this.activePopup = false;
            this.customerInfo.accessInOut = this.customerInfo.accessInOut.concat(',1')
        },
        openPopupCard: function () {
            this.activePopup = true;
        },
        getDataCard: function () {
            return new Promise((resolve, reject) => {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                const payload = {
                    cardStatus: 0,
                    cardStaffType: 2,
                    organizationId: userInfo.organization.id,
                    sort: 'id'
                }
                this.$vs.loading();
                this.$crm.post('/card-staff/find-by-condition', payload).then((res) => {
                    this.listCards = res.data.content.map(el => {
                        return {
                            label: el.id,
                            value: el.id,
                            cardStaffType: el.cardStaffType,
                            cardNumber: el.cardNumber
                        }
                    });
                    this.$vs.loading.close();
                    resolve();
                }).catch((err) => {
                    reject();
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        title: 'Error',
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                })
            })
        },
    },
}
</script>

<style scoped>
.required:after {
    content: " *";
    color: red;
}
</style>
