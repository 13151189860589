<template>
    <div class="vx-card card" @mouseover="isDelete = true" @mouseout="isDelete = false">
        <img @click="updateCardCustomer" :src="data.urlImage ? data.urlImage : urlImage" :class="{'avatar avatar-status cursor-pointer' : data.cardStatus, 'avatar avatar-unused cursor-pointer' : !data.cardStatus}">
        <div class="w-full text-center mt-4 flex" style="height:37px">
          <div class="w-full" v-show="!isDelete">
            <span class="ml-2 title font-bold">{{ data.cardNumber && (data.cardStaffType == 1 || data.cardStaffType == 2 || data.cardStaffType === 0) ? data.cardNumber : data.cardStaffId}}<span></span></span>
            <br />
            <span class="text-grey">{{ data.customerType }}</span>
            <br />
            <span class="text-grey ">{{ data.fullName }}</span>
          </div>
          <div v-show="isDelete && isEdit && !data.cardStatus" class="m-auto">
            <vx-tooltip  color="danger" :text="'Xoá thẻ'">
              <vs-button class="bg-danger" icon="icon-trash" icon-pack="feather" @click="confirmDelete"></vs-button>
            </vx-tooltip>
          </div>
          <div class="m-auto" v-show="isDelete && isEdit && data.cardStatus">
            <vx-tooltip color="warning" :text="'Gỡ thẻ'">
              <vs-button class="bg-warning" icon="icon-trash" icon-pack="feather" @click="confirmDisableCard"></vs-button>
            </vx-tooltip>                            
          </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {},
        isEdit: false,
        disableCard: false
    },
    data(){
        return {
            isDelete: false,
            urlImage: require('@/assets/images/card/businessman.jpeg'),
        }
    },
    methods: {
        updateCardCustomer: function(){
          let id = this.data.cardNumber ? this.data.cardNumber : this.data.cardStaffId;
          if (!id) id = this.data.cardNumber;
            if (this.isEdit) {
                let path = `/user/card-customer-info?id=${id}`
                this.$router.push(path)
            }
        },
        confirmDelete(){
          this.$vs.dialog({
              type: 'confirm',
              color: 'danger',
              title: this.$t('warningMenuOrganization.titleWarning'),
              text: 'Bạn có muốn xoá thẻ từ không?',
              accept: this.deleteCard,
              acceptText: this.$t('button.delete'),
              cancelText: this.$t('button.cancel')
          })
        },
        confirmDisableCard(){
          this.$vs.dialog({
              type: 'confirm',
              color: 'danger',
              title: this.$t('warningMenuOrganization.titleWarning'),
              text: 'Huỷ áp dụng thẻ cho nhân sự hoặc khách hàng?',
              accept: this.disableCardCustomer,
              acceptText: this.$t('button.delete'),
              cancelText: this.$t('button.cancel')
          })
        },
        disableCardCustomer: function(){
          let id = this.data.cardNumber ? this.data.cardNumber : this.data.cardStaffId;
          this.$vs.loading();
          this.$crm.post(`/card-staff/cancel-card/${id}`).then(() => {this.$vs.loading.close(); this.$store.commit('DELETE_CARD', true);}).catch((err) => {
            this.$vs.loading.close();
            return this.$vs.notify({
                title: 'Error',
                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            });
          })
        },
        deleteCard: function(){
          let id = this.data.cardNumber ? this.data.cardNumber : this.data.cardStaffId;
          this.$vs.loading();
          this.$crm.post(`/card-staff/delete/${id}`).then(() => {
            this.$store.commit('DELETE_CARD', true);
            this.$vs.loading.close();
          }).catch((err) => {
            this.$vs.loading.close();
            return this.$vs.notify({
                title: 'Error',
                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'danger'
            });
          })
        }
    },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat");

img {
  width: 100%;
}

.card {
  background-image: url('../../../../assets/images/card/bg-card.png');
  background-repeat: repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px;
  border-radius: 24px;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.avatar {
  height: 9rem;
  width: 9rem;
  border-radius: 50%;
  margin-bottom: 16px;
  padding: 4px;
  object-fit: contain;
  object-position: center;
}
.avatar-status {
    border: 2px solid #04d361;
    animation: borderHighlight 2s ease infinite;
}
.avatar-unused {
    filter: brightness(0.9);
}

@keyframes borderHighlight {
  50% {
    border-color: transparent;
  }
}

.title {
  font-size: 1.5rem;
  color: black;
  font-weight: 200;
}

.title span {
  font-weight: 600;
}

.circle {
  --size: 8rem;
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: #04d361;
  border-radius: 50%;
  z-index: -1;
  opacity: 0.5;
  backdrop-filter: blur(4px);
}
</style>